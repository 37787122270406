import React, { useState } from "react";
import cardPhoto from "../Images/_MG_9708.jpg";
const Card = ({ blockData, onCardClick }) => {
  return (
    <div
      className="flex flex-col cursor-pointer group  xl:transition xl:ease-in-out xl:delay-150  xl:hover:-translate-y-1 xl:hover:scale-110  xl:duration-300 border-[3px] border-cp-green"
      onClick={() => onCardClick(blockData)}
    >
      <div className="md:h-[280px] xs:h-[180px] relative bg-cp-white flex justify-center items-center overflow-hidden ">
        <img
          src={`https://media.bbf-bike.de/shop/images/${blockData?.media[0]?.IMAGENAME}.jpg`}
          className="imageContainer  transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 mt-4 mb-10"
        />
        <div className="absolute inset-x-[0px] -bottom-[12px] flex flex-row justify-center items-center left-0">
          {/* <div class="md:w-[62px] xs:w-[90px]  overflow-hidden inline-block">
            <div class="xs:h-[85px] md:h-16  bg-cp-green -rotate-45 transform origin-bottom-right"></div>
          </div> */}
          <div
            className="h-4 bg-cp-black w-[368px] flex float-left aspect-[1/1] lg:aspect-[1/2]  
                                        [clip-path:circle(70%_at_20%_30%)] 
                                        [shape-outside:circle(70%_at_20%_30%)]
                                        md:[clip-path:polygon(0%_0%,100%_0%,81%_100%,0%_100%)]
                                        md:[shape-outside:polygon(0%_0%,100%_0%,85%_100%,0%_100%)] 
                                        xs:[clip-path:polygon(0%_0%,100%_0%,86%_100%,0%_100%)]
                                      xs:[shape-outside:polygon(0%_0%,100%_0%,87%_100%,0%_100%)]
                                        transform -scale-x-100 xs:mt-10 md:mt-6 md:-ml-[44px] xs:-ml-[42px] items-center justify-start"
          >
            {/* <div className="text-[14px] text-cp-white ml-2 font-Manrope-ExtraLight transform mb-3 -scale-x-100 h-min-[250px] h-auto flex justify-center items-center gap-1">
              {blockData?.descriptions.default.TEXT1.split(" ").filter((item)=>item!=="CP" && item!=="CYCLEPARTS" && item!=="PARTS" && item!=="CYCLE").map((item,index)=>
              (
                <div className="">{item.replaceAll("\"", "")}</div>
              )
              )}
            </div> */}
          </div>
        </div>
    
      </div>
      <div className=" bg-cp-white relative text-[14px] h-[50%] flex justify-center items-center flex-col xs:gap-2 md:gap-8 px-2">
      <div className="absolute -bottom-[6px] left-0">
          <div class="md:w-[45px] xs:w-[40px]   overflow-hidden inline-block relative">
            <div class="xs:h-[85px] md:h-16 bg-cp-green -rotate-45 transform origin-bottom-right"></div>
            {/* <div className='absolute bottom-0 left-0 text-cp-white p-4 font-Manrope-ExtraLight font-semibold text-[11px]'>
              <div>BBF BIKE GmbH</div>
              <div>Carenaallee 8</div>
              <div>D-15366 Hoppegarten</div>
              <div>www.cp-cycleparts.com</div>
            </div> */}
          </div>
        </div>
      <div className="xs:text-[17px] md:text-[13px] font-bold  text-cp-black px-3 font-Manrope-ExtraLight   flex justify-center items-center">
        <div className="md:text-[16px] xs:text-[14px] xs:h-16 xl:h-10">
        {blockData?.descriptions?.default?.TEXT1?.split(" ").filter((item)=>item!=="CP" && item!=="CYCLEPARTS" && item!=="PARTS" && item!=="CYCLE").join(" ")}
        </div>
             
              {/* <div className="">{blockData?.descriptions.default.TEXT1}</div> */}
            </div>
        <div className="flex justify-center items-center flex-col  px-10 font-Manrope-ExtraLight">
          <div className="md:text-[17px] xs:text-[15px]  md:group-hover:bg-cp-gray md:group-hover:bg-opacity-20 md:px-2 md:py-1">{blockData?.RESELLERPRICE}€*</div>
          {/* <div className="font-bold text-cp-green">Name:</div>
          <h5>{blockData.descriptions.default.TEXT1}</h5>
         
          <div>{blockData.properties.default.primary.filter((item)=> item.ATTRIBUTE==="Größe").map((item,index)=>
          <div key={index}>
             <div className="font-bold text-cp-green mt-5">Größe:</div>
            {item.VALUE}
          </div>
          )}</div>
          
          <div>{blockData.properties.default.secondary.filter((item)=> item.ATTRIBUTE==="Glieder").map((item,index)=>
          <div key={index}>
            <div className="font-bold text-cp-green mt-5">Glieder:</div>
            {item.VALUE}
          </div>
          )}</div>
          
          <div>{blockData.properties.default.secondary.filter((item)=> item.ATTRIBUTE==="System Kompatibilität").map((item,index)=>
          <div key={index}>
            <div className="font-bold text-cp-green mt-5">System Kompatibilität:</div>
            {item.VALUE}
          </div>
          )}</div>
          
          <div>{blockData.properties.default.secondary.filter((item)=> item.ATTRIBUTE==="Farbe").map((item,index)=>
          <div key={index}>
            <div className="font-bold text-cp-green mt-5">Farbe:</div>
            {item.VALUE}
          </div>
          )}</div> */}

          {/* <h5>{ blockData.descriptions.default.TEXT2.split("\n").filter((item)=>item.trim() !== '').map((item,index)=>(
            <div key={index} className="">
              <li >{item}</li>

            </div>
          ))}</h5> */}
          {/* <div className="flex justify-center flex-col">
            {blockData.properties.default.primary
              .sort((a, b) => a.ATTRIBUTE.localeCompare(b.ATTRIBUTE))
              .filter(
                (item) =>
                  item.ATTRIBUTE !== "Artikelgruppe" &&
                  item.ATTRIBUTE !== "Marke"
              )
              .map((item) => (
                <div>
                  <div className="font-bold text-cp-green mt-5">
                    {item.ATTRIBUTE}
                  </div>
                  <h2>{item.VALUE}</h2>
                </div>
              ))}
            {blockData.properties.default.secondary.map((item) => (
              <div>
                <div className="font-bold text-cp-green mt-5">
                  {item.ATTRIBUTE}
                </div>
                <h2>{item.VALUE}</h2>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Card;
